import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import Layout from "../modules/layout"
import PageBuilder from "../components/page-builder"

export const query = graphql`
  {
    sanitySiteConfig {
      frontpage {
        _rawContent(resolveReferences: { maxDepth: 10 })
        _rawHero
        title
        hero {
          heading
          layout
        }
        secondaryNav {
          structure {
            child {
              ... on SanityPage {
                id
              }
            }
          }
        }
        seo {
          metaTitle
          canonical
          description
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default ({ data }) => {
  const frontpage = data.sanitySiteConfig.frontpage
  const title = frontpage.title
  const customTitle =
    frontpage.seo && frontpage.seo.metaTitle ? frontpage.seo.metaTitle : null
  const description =
    frontpage.seo && frontpage.seo.description
      ? frontpage.seo.description
      : null
  const image =
    frontpage.seo &&
    frontpage.seo.openGraphImage &&
    frontpage.seo.openGraphImage.asset !== null &&
    frontpage.seo.openGraphImage.asset !== null
      ? frontpage.seo.openGraphImage.asset.url
      : null

  return (
    <>
      <SEO
        title={title}
        description={description}
        image={image}
        customTitle={customTitle}
      />
      <Layout transition="true">
        <PageBuilder blocks={frontpage._rawContent} />
      </Layout>
    </>
  )
}
